import { getUserData } from "@/auth/utils";
import axios from "@axios";
import store from "@/store";

function beetween(a, b) {
  var min = Math.min.apply(Math, [a, b]),
    max = Math.max.apply(Math, [a, b]);
  return this > min && this < max;
}
export default {
  namespaced: true,
  stateFactory: true,
  state: {
    feedsEar: [],
    feedProperties: {},
    selectedFeed: {},
    feeds: [],
    loading: false,
    currentPage: 1,
    perPage: 10,
    sortBy: "id",
    isSortDirDesc: true,
  },
  getters: {},
  mutations: {
    SET_FEEDSEAR_TAG(state, payload) {
      payload = payload.resultdata;
      var earno = [];
      state.feedsEar = [];

      payload.map((x) => earno.push({ value: x.id, label: x.earingno }));
      state.feedsEar = earno;
    },
    SET_CURRENT_PAGE(state, payload) {
      state.currentPage = payload;
    },
    SET_SORT_BY(state, payload) {
      state.sortBy = payload;
    },
    SET_PER_PAGE(state, payload) {
      state.perPage = payload;
    },
    SET_IS_SORT_DIR_DESC(state, payload) {
      state.isSortDirDesc = payload;
    },
    SET_FEED(state, payload) {
      state.selectedFeed = payload;
    },
    SET_FEEDS(state, payload) {
      state.feeds = payload;
    },

    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    async fetchFeeds(ctx, queryParams) {
      ctx.commit("SET_LOADING", true);
      const { data } = await axios.get("Animals/GetAnimalFeeds", {
        params: queryParams ?? {
          page: ctx.state.currentPage,
          perPage: ctx.state.perPage,
          sortBy: ctx.state.sortBy,
          sortDesc: true,
          type: null,
          gender: null,
          status: null,
          sourceid: null,
          groupid: null,
          plantid: store.state.app.selectedPlantId,
          padokid: null,
        },
        headers: {
          plantid: store.state.app.selectedPlantId,
          companyid: getUserData().companyid,
        },
      });
      ctx.commit("SET_FEEDS", data.resultdata);
      ctx.commit("SET_LOADING", false);
      return data.resultdata;
    },
    async fetchAnimalForAnimalFeeds(ctx, queryParams) {
      ctx.commit("SET_LOADING", true);
      const { data } = await axios.get("Animals/GetAnimalsForAnimalFeed", {
        headers: {
          plantid: store.state.app.selectedPlantId,
          companyid: getUserData().companyid,
        },
      });
      ctx.commit("SET_LOADING", false);
      return data.resultdata;
    },

    deleteFeed(ctx, queryParams) {
      ctx.commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .delete("/Animals/DeleteFeed/" + queryParams.id)
          .then((response) => {
            ctx.commit("SET_LOADING", false);
            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchFeed(ctx, id) {
      ctx.commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/Animals/GetFeedByid/`, {
            headers: {
              feedid: id,
            },
          })
          .then((response) => {
            ctx.commit("SET_LOADING", false);
            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    addFeed(ctx, feedData) {
      ctx.commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .post("/Animals/AddAnimalFeed", feedData)
          .then((response) => {
            ctx.commit("SET_LOADING", false);
            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateFeed(ctx, feedData) {
      ctx.commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .put(`/Animals/UpdateAnimalFeed/${feedData.animalid}`, feedData)
          .then((response) => {
            ctx.commit("SET_LOADING", false);
            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
