import axios from "@axios";
import VueI18n from "@/libs/i18n";

export default {
  async fetchTreatments(ctx) {
    var response = await axios.get("/Definitions/GetTreatments");
    if (response.data.status != "OK") return null;
    ctx.commit("SET_TREATMENTS", response.data.resultdata);

    return response.data.resultdata;
  },
  async fetchTreatmentTypes(ctx) {
    var response = await axios.get("/Definitions/GetTreatmentTypes");
    if (response.data.status != "OK") return null;
    ctx.commit("SET_TREATMENT_TYPES", response.data.resultdata);
    return response.data.resultdata;
  },

  async addTreatment(ctx, treatment) {
    var response = await axios.post("/Definitions/AddTreatment/", treatment);
    return response.data.status == "OK";
  },
  async addTreatmentType(ctx, treatmentType) {
    var response = await axios.post(
      "/Definitions/AddTreatmentType/",
      treatmentType
    );
    return response.data.status == "OK";
  },
  async updateTreatment(ctx, treatment) {
    var response = await axios.put(
      `/Definitions/UpdateTreatment/${treatment.id}`,
      treatment
    );

    return response.data.status == "OK";
  },
  async updateTreatmentType(ctx, treatmentType) {
    var response = await axios.put(
      `/Definitions/UpdateTreatmentType/${treatmentType.id}`,
      treatmentType
    );
    return response.data.status == "OK";
  },
  async deleteTreatment(ctx, treatmentid) {
    var response = {};
    return await this._vm
      .$swal({
        title: VueI18n.t("areYouSure"),
        text: VueI18n.t("notRevert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: VueI18n.t("yes"),
        cancelButtonText: VueI18n.t("no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
      .then(async (result) => {
        if (result.value) {
          var response = await axios.delete(
            `/Definitions/DeleteTreatment/${treatmentid}`
          );
          return response.data.status == "OK";
        } else return false;
      });
  },
  async deleteTreatmentType(ctx, treatmentTypeid) {
    var response = {};
    return await this._vm

      .$swal({
        title: VueI18n.t("areYouSure"),
        text: VueI18n.t("notRevert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: VueI18n.t("yes"),
        cancelButtonText: VueI18n.t("no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
      .then(async (result) => {
        if (result.value) {
          var response = await axios.delete(
            `/Definitions/DeleteTreatmentType/${treatmentTypeid}`
          );
          return response.data.status == "OK";
        } else return false;
      });
  },
};
