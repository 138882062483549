import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import companiesModule from "./app/companiesStoreModule";
import animalsModule from "./app/animalsStoreModule";
import usersModule from "./app/usersStoreModule";
import modulesModule from "./app/modulesStoreModule";
import visitsModule from "./app/visitsStoreModule";
import ordersModule from "./app/ordersStoreModule";
import plantsModule from "./app/plantsStoreModule";
import rationsModule from "./app/rationsStoreModule";
import companyProductsModule from "./app/companyProductStoreModule";
import definitionsModule from "./app/definitionsStoreModule";
import machinesModule from "./app/machinesStoreModule";
import feedsModule from "./app/feedsStoreModule";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    companiesModule,
    animalsModule,
    usersModule,
    modulesModule,
    visitsModule,
    ordersModule,
    companyProductsModule,
    plantsModule,
    rationsModule,
    definitionsModule,
    machinesModule,
    feedsModule,
  },
  strict: process.env.DEV,
});
