import axios from "@axios";
import VueI18n from "@/libs/i18n";
import store from "@/store";
export default {
  async fetchVaccines(ctx, plantid) {
    var response = await axios.get("/Definitions/GetVaccines", {
      headers: { plantid: store.state.app.selectedPlantId },
    });
    if (response.data.status != "OK") return null;
    ctx.commit("SET_VACCINES", response.data.resultdata);

    return response.data.resultdata;
  },

  async fetchVendorVaccines(ctx) {
    var response = await axios.get("/Definitions/GetVendorVaccines");
    if (response.data.status != "OK") return null;
    ctx.commit("SET_VACCINES", response.data.resultdata);

    return response.data.resultdata;
  },

  async fetchVaccinePurchases(ctx, id) {
    var response = await axios.get("/Definitions/GetVaccinePurchases/" + id, {
      headers: { plantid: store.state.app.selectedPlantId },
    });
    if (response.data.status != "OK") return null;
    return response.data.resultdata;
  },

  async addVaccinePurchase(ctx, vaccine) {
    var response = await axios.post(
      "/Definitions/AddVaccinePurchase",
      vaccine,
      {
        headers: { plantid: store.state.app.selectedPlantId },
      }
    );
    return response.data.status == "OK";
  },
  async updateVaccinePurchase(ctx, vaccine) {
    var response = await axios.put(
      `/Definitions/UpdateVaccinePurchase/0`,
      vaccine
    );

    return response.data.status == "OK";
  },
  async deleteVaccinePurchase(ctx, vaccineid) {
    var response = {};
    return await this._vm
      .$swal({
        title: VueI18n.t("areYouSure"),
        text: VueI18n.t("notRevert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: VueI18n.t("yes"),
        cancelButtonText: VueI18n.t("no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
      .then(async (result) => {
        if (result.value) {
          var response = await axios.delete(
            `/Definitions/DeleteVaccinePurchase/${vaccineid}`
          );
          return response.data.status == "OK";
        } else return false;
      });
  },
  async fetchVaccines(ctx, plantid) {
    var response = await axios.get("/Definitions/GetVaccines", {
      headers: { plantid: store.state.app.selectedPlantId },
    });
    if (response.data.status != "OK") return null;
    ctx.commit("SET_VACCINES", response.data.resultdata);

    return response.data.resultdata;
  },

  async addVaccine(ctx, vaccine) {
    var response = await axios.post("/Definitions/AddVaccine", vaccine, {
      headers: { plantid: store.state.app.selectedPlantId },
    });
    return response.data.status == "OK";
  },
  async updateVaccine(ctx, vaccine) {
    var response = await axios.put(
      `/Definitions/UpdateVaccine/${vaccine.id}`,
      vaccine
    );

    return response.data.status == "OK";
  },
  async deleteVaccine(ctx, vaccineid) {
    var response = {};
    return await this._vm
      .$swal({
        title: VueI18n.t("areYouSure"),
        text: VueI18n.t("notRevert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: VueI18n.t("yes"),
        cancelButtonText: VueI18n.t("no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
      .then(async (result) => {
        if (result.value) {
          var response = await axios.delete(
            `/Definitions/DeleteVaccine/${vaccineid}`
          );
          return response.data.status == "OK";
        } else return false;
      });
  },
};
