import { getUserData } from "@/auth/utils";
import axios from "@axios";
import store from "@/store";
import VueI18n from "@/libs/i18n";

export default {
  namespaced: true,
  stateFactory: true,
  state: {
    animalsEar: [],
    groupData: [],
    animalsAlarms: [],
    selectedGroup: {},
    animalProperties: {},
    selectedAnimal: {},
    animals: [],
    loading: false,
    currentPage: 1,
    perPage: 10,
    sortBy: "id",
    isSortDirDesc: true,
    isEditAnimalSidebarActive: false,
  },
  getters: {},
  mutations: {
    SET_EDIT_SIDEBAR(state, payload) {
      state.isEditAnimalSidebarActive = payload;
    },
    SET_ANIMALSEAR_TAG(state, payload) {
      payload = payload.resultdata;
      var earno = [];
      state.animalsEar = [];

      payload.map((x) => earno.push({ value: x.id, label: x.earingno }));
      state.animalsEar = earno;
    },
    SET_CURRENT_PAGE(state, payload) {
      state.currentPage = payload;
    },
    SET_SORT_BY(state, payload) {
      state.sortBy = payload;
    },
    SET_PER_PAGE(state, payload) {
      state.perPage = payload;
    },
    SET_IS_SORT_DIR_DESC(state, payload) {
      state.isSortDirDesc = payload;
    },
    SET_ANIMAL(state, payload) {
      state.selectedAnimal = payload;
    },
    SET_ANIMALS(state, payload) {
      state.animals = payload;
    },
    SET_GROUPS(state, payload) {
      state.groupData = payload;
    },
    SET_GROUP(state, payload) {
      state.selectedGroup = payload;
    },
    SET_ANIMALPROPERTIES(state, payload) {
      state.animalProperties = payload;
    },
    SET_ANIMALS_ALARMS(state, payload) {
      state.animalsAlarms = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    async fetchAnimals(ctx, queryParams) {
      ctx.commit("SET_LOADING", true);
      const { data } = await axios.get("/Animals/GetAnimals", {
        params: queryParams ?? {
          page: ctx.state.currentPage,
          perPage: ctx.state.perPage,
          sortBy: ctx.state.sortBy,
          sortDesc: true,
          type: null,
          gender: null,
          status: null,
          sourceid: null,
          groupid: null,
          plantid: store.state.app.selectedPlantId,
          padokid: null,
        },
        headers: {
          plantid: store.state.app.selectedPlantId,
          companyid: getUserData().companyid,
        },
      });
      ctx.dispatch("fetchAnimalsIdAndEaringno");
      ctx.commit("SET_ANIMALS", data.resultdata);
      var selectedAnimal = { ...ctx.state.selectedAnimal };
      ctx.state.animals.find((x) => {
        if (x.id == selectedAnimal.id) {
          selectedAnimal = x;
        }
      });
      ctx.commit("SET_ANIMAL", selectedAnimal);
      ctx.commit("SET_LOADING", false);
      return data.resultdata;
    },
    async fetchAnimalsIdAndEaringno(ctx) {
      ctx.commit("SET_LOADING", true);
      const { data } = await axios.get("/Animals/GetAnimalsIdAndEaringno", {
        headers: {
          plantid: store.state.app.selectedPlantId,
          companyid: getUserData().companyid,
        },
      });

      ctx.commit("SET_ANIMALSEAR_TAG", data);
      ctx.commit("SET_LOADING", false);
      return data.resultdata;
    },
    async fetchAnimalsAlarms(ctx) {
      ctx.commit("SET_LOADING", true);
      const { data } = await axios.get("/Animals/GetAnimalsAlarms", {
        headers: {
          plantid: store.state.app.selectedPlantId,
          companyid: getUserData().companyid,
        },
      });
      console.log(data);

      ctx.commit("SET_ANIMALS_ALARMS", data.resultdata);
      ctx.commit("SET_LOADING", false);
      return data.resultdata;
    },
    deleteAnimal(ctx, queryParams) {
      ctx.commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .delete("/Animals/DeleteAnimal/" + queryParams.id)
          .then((response) => {
            ctx.commit("SET_LOADING", false);
            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    async deleteBirth(ctx, visitid) {
      var response = {};
      return await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            ctx.commit("SET_LOADING", true);
            var response = await axios.delete(`/Births/DeleteBirth/${visitid}`);
            ctx.commit("SET_LOADING", false);
            return response.data.status == "OK";
          } else return false;
        });
    },
    fetchAnimal(ctx, id) {
      ctx.commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/Animals/GetAnimalByid/`, {
            headers: {
              animalid: id,
            },
          })
          .then((response) => {
            ctx.commit("SET_LOADING", false);
            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    addAnimal(ctx, animalData) {
      ctx.commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .post("/Animals/AddAnimal", animalData)
          .then((response) => {
            ctx.commit("SET_LOADING", false);
            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    addBirth(ctx, birthData) {
      ctx.commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .post("/Animals/AddBirth", birthData)
          .then((response) => {
            ctx.commit("SET_LOADING", false);
            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateAnimal(ctx, animalData) {
      ctx.commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .put(`/Animals/UpdateAnimal/${animalData.id}`, animalData)
          .then((response) => {
            ctx.commit("SET_LOADING", false);

            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateBirth(ctx, birthData) {
      ctx.commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .put(`/Animals/UpdateBirth/${birthData.id}`, birthData)
          .then((response) => {
            ctx.commit("SET_LOADING", false);
            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    // Animal Milking
    async fetchAnimalMilking(ctx, animalid) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.get(
        `/Animals/GetAnimalMilking/${animalid.animalid}`,
        {
          headers: {
            ...animalid,
          },
        }
      );
      ctx.commit("SET_LOADING", false);
      return response.data.resultdata ?? [];
    },

    async addAnimalMilking(ctx, animalMilkingData) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.post(
        "/Animals/AddAnimalMilking",
        animalMilkingData
      );
      ctx.commit("SET_LOADING", false);
      return response.data;
    },
    async updateAnimalMilking(ctx, animalMilkingData) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.put(
        `/Animals/UpdateAnimalMilking/${animalMilkingData.id}`,
        animalMilkingData
      );
      ctx.commit("SET_LOADING", false);
      return response.data;
    },
    async deleteAnimalMilking(ctx, id) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.delete(`/Animals/DeleteAnimalMilking/${id}`);
      ctx.commit("SET_LOADING", false);
      return response.data.status == "OK";
    },
    async addAnimalGroupMilking(ctx, animalGroupMilkingData) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.post(
        "/Animals/AddAnimalGroupMilking",
        animalGroupMilkingData
      );
      ctx.commit("SET_LOADING", false);
      return response.data.status == "OK";
    },
    //Animal Groups
    async fetchGroups(ctx) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.get("/Definitions/GetAnimalGroups", {
        headers: {
          plantid: store.state.app.selectedPlantId,
        },
      });
      if (response.data.status != "OK") return null;
      ctx.commit("SET_GROUPS", response.data.resultdata);

      ctx.commit("SET_LOADING", false);
      return response.data.resultdata ?? [];
    },

    async addGroup(ctx, group) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.post("/Definitions/AddAnimalGroup/", group);
      ctx.commit("SET_LOADING", false);
      return response.data.status == "OK";
    },
    async updateGroup(ctx, group) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.put(
        `/Definitions/UpdateAnimalGroup/${group.id}`,
        group
      );
      ctx.commit("SET_LOADING", false);
      return response.data.status == "OK";
    },
    async deleteGroup(ctx, groupid) {
      ctx.commit("SET_LOADING", true);
      var response = {};
      return await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            var response = await axios.delete(
              `/Definitions/DeletAnimalGroup/${groupid}`
            );
            ctx.commit("SET_LOADING", false);
            return response.data.status == "OK";
          } else {
            ctx.commit("SET_LOADING", false);
            return false;
          }
        });
    },
    // Animal Conditions
    async fetchAnimalCondition(ctx, animalid) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.get(
        `/Animals/GetAnimalWeightsandConditions/${animalid.animalid}`,
        {
          headers: {
            ...animalid,
          },
        }
      );
      ctx.commit("SET_LOADING", false);
      return response.data.resultdata ?? [];
    },

    async addAnimalCondition(ctx, animalConditionData) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.post(
        "/Animals/AddAnimalWeightandCondition",
        animalConditionData
      );
      ctx.commit("SET_LOADING", false);
      return response.data;
    },
    async updateAnimalCondition(ctx, animalConditionData) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.put(
        `/Animals/UpdateAnimalWeightandCondition/${animalConditionData.id}`,
        animalConditionData
      );
      ctx.commit("SET_LOADING", false);
      return response.data;
    },
    async deleteAnimalCondition(ctx, id) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.delete(
        `/Animals/DeleteAnimalWeightandCondition/${id}`
      );
      ctx.commit("SET_LOADING", false);
      return response.data.status == "OK";
    },
    // Animal Insemination
    async fetchAnimalInsemination(ctx, animalid) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.get(
        `/Animals/GetAnimalInseminations/${animalid.animalid}`,
        {
          headers: {
            ...animalid,
          },
        }
      );
      ctx.commit("SET_LOADING", false);
      return response.data.resultdata ?? [];
    },
    async fetchInseminationStatuses(ctx) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.get("/Animals/GetInseminationStatuses");
      ctx.commit("SET_LOADING", false);
      return response.data.resultdata ?? [];
    },
    async fetchInseminationMethods(ctx) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.get("/Animals/GetInseminationMethods");
      ctx.commit("SET_LOADING", false);
      return response.data.resultdata ?? [];
    },
    async addAnimalInsemination(ctx, animalInseminationData) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.post(
        "/Animals/AddAnimalInsemination",
        animalInseminationData
      );
      ctx.commit("SET_LOADING", false);
      return response.data;
    },
    async updateAnimalInsemination(ctx, animalInseminationData) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.put(
        `/Animals/UpdateAnimalInsemination/${animalInseminationData.id}`,
        animalInseminationData
      );
      ctx.commit("SET_LOADING", false);
      return response.data;
    },
    async deleteAnimalInsemination(ctx, id) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.delete(
        `/Animals/DeleteAnimalInsemination/${id}`
      );
      ctx.commit("SET_LOADING", false);
      return response.data.status == "OK";
    },
    // SlaugterHouses
    async fetchSlaugterHouses(ctx) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.get("/Animals/GetKesimhaneList");
      ctx.commit("SET_LOADING", false);
      return response.data.resultdata ?? [];
    },
    async deleteAnimalFromSlaugterHouse(ctx, id) {
      await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            var response = await axios.delete(
              `/Animals/DeleteAnimalFromKesimhaneList/${id}`
            );
            return response;
          } else throw Error("iptal");
        });
    },
    async addAnimalToSlaugterHouse(ctx, data) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.post(
        "/Animals/AddAnimalToKesimhaneList",
        data
      );
      const animal = { ...ctx.state.selectedAnimal, kesimhaneListesinde: 1 };
      ctx.commit("SET_ANIMAL", animal);

      ctx.commit("SET_LOADING", false);
      return response.statusdata == 1;
    },
    // Animal Birth
    async fetchAnimalBirth(ctx, animalid) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.get(
        `/Animals/GetAnimalBirths/${animalid.animalid}`,
        {
          headers: {
            ...animalid,
          },
        }
      );
      ctx.commit("SET_LOADING", false);
      return response.data.resultdata ?? [];
    },
    async addAnimalBirth(ctx, animalBirthData) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.post("/Animals/AddBirth", animalBirthData);
      ctx.commit("SET_LOADING", false);
      return response.data;
    },
    async updateAnimalBirth(ctx, animalBirthData) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.put(
        `/Animals/UpdateBirth/${animalBirthData.id}`,
        animalBirthData
      );

      ctx.commit("SET_LOADING", false);
      return response.data;
    },
    async deleteAnimalBirth(ctx, id) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.delete(`/Animals/DeleteBirth/${id}`);
      ctx.commit("SET_LOADING", false);
      return response.data.status == "OK";
    },
    // Animal Exam
    async fetchAnimalExams(ctx, animalid) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.get(`/Animals/GetAnimalExams/${animalid}`, {
        headers: {
          animalid,
        },
      });
      ctx.commit("SET_LOADING", false);
      return response.data.resultdata ?? [];
    },

    async addAnimalExam(ctx, animalExamData) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.post("/Animals/AddAnimalExam", animalExamData);
      ctx.commit("SET_LOADING", false);
      return response.data;
    },
    async updateAnimalExam(ctx, animalExamData) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.put(
        `/Animals/UpdateAnimalExam/${animalExamData.id}`,
        animalExamData
      );
      ctx.commit("SET_LOADING", false);
      return response.data;
    },
    async deleteAnimalExam(ctx, id) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.delete(`/Animals/DeleteAnimalExam/${id}`);
      ctx.commit("SET_LOADING", false);
      return response.data.status == "OK";
    },
    // Animal Medicine
    async fetchAnimalMedicines(ctx, animalid) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.get(
        `/Animals/GetAnimalMedicines/${animalid}`,
        {
          headers: {
            animalid,
          },
        }
      );
      ctx.commit("SET_LOADING", false);
      return response.data.resultdata ?? [];
    },
    async addAnimalMedicine(ctx, animalMedicineData) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.post(
        "/Animals/AddAnimalMedicine",
        animalMedicineData
      );
      ctx.commit("SET_LOADING", false);
      return response.data;
    },
    async updateAnimalMedicine(ctx, animalMedicineData) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.put(
        `/Animals/UpdateAnimalMedicine/${animalMedicineData.id}`,
        animalMedicineData
      );
      ctx.commit("SET_LOADING", false);
      return response.data;
    },
    async deleteAnimalMedicine(ctx, id) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.delete(`/Animals/DeleteAnimalMedicine/${id}`);
      ctx.commit("SET_LOADING", false);
      return response.data.status == "OK";
    },
    // Animal Vaccine
    async fetchAnimalVaccines(ctx, animalid) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.get(`/Animals/GetAnimalVaccines/${animalid}`, {
        headers: {
          animalid,
        },
      });
      ctx.commit("SET_LOADING", false);
      return response.data.resultdata ?? [];
    },
    async addAnimalVaccine(ctx, animalVaccineData) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.post(
        "/Animals/AddAnimalVaccine",
        animalVaccineData
      );
      ctx.commit("SET_LOADING", false);
      return response.data;
    },
    async updateAnimalVaccine(ctx, animalVaccineData) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.put(
        `/Animals/UpdateAnimalVaccine/${animalVaccineData.id}`,
        animalVaccineData
      );
      ctx.commit("SET_LOADING", false);
      return response.data;
    },
    async deleteAnimalVaccine(ctx, id) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.delete(`/Animals/DeleteAnimalVaccine/${id}`);
      ctx.commit("SET_LOADING", false);
      return response.data.status == "OK";
    },

    // Animal Status
    async fetchAnimalStatuses(ctx, animalid) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.get(
        `/Animals/GetAnimalsStatuses/${animalid}`,
        {
          headers: {
            animalid,
          },
        }
      );
      ctx.commit("SET_LOADING", false);
      return response.data.resultdata ?? [];
    },
    async addAnimalStatus(ctx, animalStatusData) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.post(
        "/Animals/AddAnimalsStatus",
        animalStatusData
      );
      ctx.commit("SET_LOADING", false);
      return response.data;
    },
    async updateAnimalStatus(ctx, animalStatusData) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.put(
        `/Animals/UpdateAnimalsStatus/${animalStatusData.id}`,
        animalStatusData
      );
      ctx.commit("SET_LOADING", false);
      return response.data;
    },
    async deleteAnimalStatus(ctx, id) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.delete(`/Animals/DeleteAnimalsStatus/${id}`);
      ctx.commit("SET_LOADING", false);
      return response.data.status == "OK";
    },
    // Animal Group
    async fetchAnimalGroupsforSelection(ctx, animalid) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.get(
        `/Animals/GetAnimalGroupsforSelection/${animalid}`,
        {
          headers: {
            animalid,
          },
        }
      );
      ctx.commit("SET_LOADING", false);
      return response.data.resultdata ?? [];
    },
    async fetchGroupsofAnimal(ctx, animalid) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.get(`/Animals/GetGroupsofAnimal/${animalid}`, {
        headers: {
          animalid,
        },
      });
      ctx.commit("SET_LOADING", false);
      return response.data.resultdata ?? [];
    },
    async updateAnimalsGroups(ctx, animalGroupsData) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.put(
        `/Animals/UpdateAnimalsGroups/${animalGroupsData.id}`,
        animalGroupsData.data,
        {
          headers: {
            ids: animalGroupsData.data,
          },
        }
      );
      ctx.commit("SET_LOADING", false);
      return response.data;
    },
    // Animal Feeding
    async fetchAnimalFeeds(ctx, { animalid, type }) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.get(
        `/Animals/GetAnimalsFeeds${type == 0 ? "" : "Kaba"}/${animalid}`,
        {
          headers: {
            animalid,
          },
        }
      );
      ctx.commit("SET_LOADING", false);
      return response.data.resultdata ?? [];
    },
    async addAnimalFeed(ctx, animalFeedData) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.post(
        "/Animals/AddAnimalsFeed",
        animalFeedData
      );
      ctx.commit("SET_LOADING", false);
      return response.data;
    },

    async updateAnimalFeed(ctx, animalFeedData) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.put(
        `/Animals/UpdateAnimalsFeed/${animalFeedData.id}`,
        animalFeedData
      );
      ctx.commit("SET_LOADING", false);
      return response.data;
    },
    async updateAnimalFeedKaba(ctx, animalFeedData) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.put(
        `/Animals/UpdateAnimalsFeedKaba/${animalFeedData.id}`,
        animalFeedData
      );
      ctx.commit("SET_LOADING", false);
      return response.data;
    },
    async deleteAnimalFeed(ctx, { id, type }) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.delete(
        `/Animals/DeleteAnimalsFeed${type == 0 ? "" : "Kaba"}/${id}`
      );
      ctx.commit("SET_LOADING", false);
      return response.data.status == "OK";
      // Animal Milkings
    },
    async fetchAnimalMilkings(ctx, { reportdate }) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.get(`/Animals/GetAnimalsMilkings/`, {
        headers: {
          reportdate,
        },
      });
      ctx.commit("SET_LOADING", false);
      return response.data.resultdata ?? [];
    },
    async fetchAnimalsWeights(ctx, { reportdate }) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.get(`/Animals/GetAnimalsWeights/`, {
        headers: {
          reportdate,
        },
      });
      ctx.commit("SET_LOADING", false);
      return response.data.resultdata ?? [];
    },
    async fetchAnimalMobilities(ctx, id) {
      ctx.commit("SET_LOADING", true);
      var response = await axios.get(`/Animals/GetAnimalRumimonData/${id}`);

      ctx.commit("SET_LOADING", false);
      return response.data.resultdata ?? [];
    },
  },
};
