import axios from "@axios";
import VueI18n from "@/libs/i18n";

export default {
  async fetchNotes(ctx, plantid) {
    var response = await axios.get("/Definitions/GetNotes", {
      headers: {
        plantid,
      },
    });
    if (response.data.status != "OK") return null;
    ctx.commit("SET_NOTES", response.data.resultdata);

    return response.data.resultdata;
  },

  async addNote(ctx, sperma) {
    var response = await axios.post("/Definitions/AddNote/", sperma);
    return response.data.status == "OK";
  },
  async updateNote(ctx, sperma) {
    var response = await axios.put(
      `/Definitions/UpdateNote/${sperma.id}`,
      sperma
    );

    return response.data.status == "OK";
  },
  async deleteNote(ctx, noteid) {
    var response = {};
    return await this._vm
      .$swal({
        title: VueI18n.t("areYouSure"),
        text: VueI18n.t("notRevert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: VueI18n.t("yes"),
        cancelButtonText: VueI18n.t("no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
      .then(async (result) => {
        if (result.value) {
          var response = await axios.delete(
            `/Definitions/DeleteNote/${noteid}`
          );
          return response.data.status == "OK";
        } else return false;
      });
  },
};
