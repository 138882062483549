import { getUserData } from "@/auth/utils";
import axios from "@axios";
import dayjs from "dayjs";
import VueI18n from "@/libs/i18n";

// Notification
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import feed from "./feed/feed";
import { BIconEmojiNeutralFill } from "bootstrap-vue";

export default {
  namespaced: true,
  state: {
    rationData: [],
    selectedRation: {},
    productionRationData: [],
    selectedProductionRation: {},
    allIngredients: [],
    allNutrients: [],
    selectedRationIngredients: [],
    selectedRationNutrients: [],
    ingredientLoading: true,
    nutrientLoading: true,
    rationLoading: true,
    cozdu: null,
    animalFeeds: [],
    plantAnimalFeeds: [],
  },
  getters: {},
  mutations: {
    SET_RATIONS(state, payload) {
      state.rationData = payload;
    },
    SET_RATION(state, payload) {
      state.selectedRation = payload;
    },
    SET_PRODUCTION_RATIONS(state, payload) {
      state.productionRationData = payload;
    },
    SET_PRODUCTION_RATION(state, payload) {
      state.selectedProductionRation = payload;
    },
    SET_ANIMAL_FEEDS(state, payload) {
      state.animalFeeds = payload;
    },
    SET_PLANT_ANIMAL_FEEDS(state, payload) {
      state.plantAnimalFeeds = payload;
    },
    SET_RATION_TUR(state, payload) {
      state.selectedRation.tur = payload;
      state.selectedRation.tur = payload;
    },
  },
  actions: {
    ...feed,
    async fetchRations(ctx, { tur, plantid }) {
      let response = await axios.get("/Rations/GetRations/", {
        headers: {
          plantid: plantid,
          tur: tur,
        },
      });
      if (response.data.status != "OK") return null;
      ctx.commit("SET_RATIONS", response.data.resultdata);
      return response.data.resultdata;
    },

    async fetchRation(ctx, { id, tur, plantid }) {
      ctx.state.rationLoading = true;
      ctx.state.nutrientLoading = true;
      ctx.state.ingredientLoading = true;
      let response = await axios.get("/Rations/GetRation/" + id, {
        headers: {
          plantid: plantid,
          tur: tur,
        },
      });
      ctx.state.rationLoading = false;

      if (response.data.status != "OK") return null;
      ctx.commit("SET_RATION", response.data.resultdata[0]);
      ctx.commit("SET_RATION_TUR", tur);
      return response.data.resultdata[0];
    },
    async fetchProductionRations(ctx, { tur, plantid }) {
      let response = await axios.get("/Rations/GetProductionRations/", {
        headers: {
          plantid: plantid,
          tur: tur,
        },
      });
      if (response.data.status != "OK") return null;
      ctx.commit("SET_PRODUCTION_RATIONS", response.data.resultdata);
      return response.data.resultdata;
    },
    async fetchProductionRation(ctx, { id, tur, plantid }) {
      ctx.state.rationLoading = true;
      ctx.state.nutrientLoading = true;
      ctx.state.ingredientLoading = true;
      let response = await axios.get("/Rations/GetProductionRation/" + id, {
        headers: {
          plantid: plantid,
          tur: tur,
        },
      });
      ctx.state.rationLoading = false;

      if (response.data.status != "OK") return null;
      ctx.commit("SET_PRODUCTION_RATION", response.data.resultdata[0]);
      return response.data.resultdata[0];
    },
    async deleteRation(ctx, { rationid, plantid, tur }) {
      var response = {};
      var response = await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            response = await axios.delete(`/Rations/DeleteRation/${rationid}`, {
              headers: { plantid, tur },
            });
            return response.data.status != "OK";
          } else return false;
        });
      if (response) {
        this._vm.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: VueI18n.t("successful"),
            icon: "AlertCircleIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("ration") }),
          },
        });
      } else {
        this._vm.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertCircleIcon",
            variant: "danger",
            text: VueI18n.t("notDeleted", { type: VueI18n.t("ration") }),
          },
        });
      }
      return response;
    },
    async deleteProductionRation(ctx, { rationid, plantid, tur }) {
      var response = {};
      var response = await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            response = await axios.delete(
              `/Rations/DeleteProductionRation/${rationid}`,
              {
                headers: { plantid, tur },
              }
            );
            return response.data.status != "OK";
          } else return false;
        });
      if (response) {
        this._vm.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: VueI18n.t("successful"),
            icon: "AlertCircleIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("ration") }),
          },
        });
      } else {
        this._vm.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertCircleIcon",
            variant: "danger",
            text: VueI18n.t("notDeleted", { type: VueI18n.t("ration") }),
          },
        });
      }
      return response;
    },

    async postDairyNRCME(ctx, { ration, plantid }) {
      let response = await axios.post("/Rations/AddDairyRationNRCME/", ration, {
        headers: {
          plantid: plantid,
        },
      });
      return response.data.status == "OK";
    },
    async postDairyNasem(ctx, { ration, plantid }) {
      let response = await axios.post("/Rations/AddDairyRationNASEM/", ration, {
        headers: {
          plantid: plantid,
        },
      });
      return response.data.status == "OK";
    },
    async postBeefNasem(ctx, { ration, plantid }) {
      let response = await axios.post("/Rations/AddBeefRationNASEM/", ration, {
        headers: {
          plantid: plantid,
        },
      });
      return response.data.status == "OK";
    },
    async postDryNRCME(ctx, { ration, plantid }) {
      let response = await axios.post("/Rations/AddDryRationNRCME/", ration, {
        headers: {
          plantid: plantid,
        },
      });
      return response.data.status == "OK";
    },
    async postHeiferNRCME(ctx, { ration, plantid }) {
      let response = await axios.post(
        "/Rations/AddHeiferRationNRCME/",
        ration,
        {
          headers: {
            plantid: plantid,
          },
        }
      );
      return response.data.status == "OK";
    },
    async postDairyNRCNE(ctx, { ration, plantid }) {
      let response = await axios.post("/Rations/AddDairyRationNRCNE/", ration, {
        headers: {
          plantid: plantid,
        },
      });
      return response.data.status == "OK";
    },
    async postDryNRCNE(ctx, { ration, plantid }) {
      let response = await axios.post("/Rations/AddDryRationNRCNE/", ration, {
        headers: {
          plantid: plantid,
        },
      });
      return response.data.status == "OK";
    },
    async postHeiferNRCNE(ctx, { ration, plantid }) {
      let response = await axios.post(
        "/Rations/AddHeiferRationNRCNE/",
        ration,
        {
          headers: {
            plantid: plantid,
          },
        }
      );
      return response.data.status == "OK";
    },
    async postDairyINRA(ctx, { ration, plantid }) {
      let response = await axios.post("/Rations/AddDairyRationINRA/", ration, {
        headers: {
          plantid: plantid,
        },
      });
      return response.data.status == "OK";
    },
    async postBeefNRCME(ctx, { ration, plantid }) {
      let response = await axios.post("/Rations/AddBeefRationNRCME/", ration, {
        headers: {
          plantid: plantid,
        },
      });
      return response.data.status == "OK";
    },
    async postBeefINRA(ctx, { ration, plantid }) {
      let response = await axios.post("/Rations/AddBeefRationINRA/", ration, {
        headers: {
          plantid: plantid,
        },
      });
      return response.data.status == "OK";
    },
    async postBeefNRCNE(ctx, { ration, plantid }) {
      let response = await axios.post("/Rations/AddBeefRationNRCNE/", ration, {
        headers: {
          plantid: plantid,
        },
      });
      return response.data.status == "OK";
    },
    async updateIngredients(ctx) {
      let selectedIngredients = ctx.state.allIngredients.filter(
        (ingredient) =>
          ingredient.selected == 1 &&
          !ctx.state.selectedRation.hammaddeler.some(
            (element) => element.ikey == ingredient.ikey
          )
      );
      let alreadyIngredients = ctx.state.allIngredients
        .map((ingredient) => {
          if (ingredient.selected == 1)
            return ctx.state.selectedRation.hammaddeler.filter(
              (element) => element.ikey == ingredient.ikey
            );
        })
        .filter((e) => e && Object.keys(e).length)
        .flat();
      ctx.state.selectedRation.hammaddeler = [];
      let hammaddeler = [...alreadyIngredients, ...selectedIngredients];
      ctx.state.selectedRation.hammaddeler = hammaddeler;
    },
    async updateNutrients(ctx) {
      let selectedNutrients = ctx.state.allNutrients.filter(
        (ingredient) =>
          ingredient.selected == 1 &&
          !ctx.state.selectedRation.besinmaddeleri.some(
            (element) => element.nkey == ingredient.nkey
          )
      );
      let alreadyNutrients = ctx.state.allNutrients
        .map((ingredient) => {
          if (ingredient.selected == 1)
            return ctx.state.selectedRation.besinmaddeleri.filter(
              (element) => element.nkey == ingredient.nkey
            );
        })
        .filter((e) => e && Object.keys(e).length)
        .flat();
      ctx.state.selectedRation.besinmaddeleri = [];
      let besinmaddeleri = [...alreadyNutrients, ...selectedNutrients];
      ctx.state.selectedRation.besinmaddeleri = besinmaddeleri;

      for (let i = 0; i < ctx.state.selectedRation.besinmaddeleri.length; i++) {
        ctx.state.selectedRation.besinmaddeleri[i].deger =
          ctx.state.selectedRation.besinmaddeleri[i].deger ?? 0;
        ctx.state.selectedRation.besinmaddeleri[i].min =
          ctx.state.selectedRation.besinmaddeleri[i].min ?? 0;
        ctx.state.selectedRation.besinmaddeleri[i].max =
          ctx.state.selectedRation.besinmaddeleri[i].max ?? 0;
      }
    },
    async getRationIngredients(ctx) {
      ctx.state.selectedRationIngredients = [];
      if (ctx.state.selectedRation.hammaddeler == null)
        ctx.state.selectedRation.hammaddeler = [];
      const ids = ctx.state.selectedRation.hammaddeler.map((e) => e.ikey);
      ctx.state.selectedRationIngredients = await ctx.dispatch(
        "getRationIngredientsAll",
        { plantid: ctx.state.selectedRation.rasyon[0].plantid, ids }
      );

      ctx.state.allIngredients = ctx.state.selectedRationIngredients;
    },
    async getRationNutrients(ctx) {
      ctx.state.selectedRationIngredients = [];
      if (ctx.state.selectedRation.besinmaddeleri == null)
        ctx.state.selectedRation.besinmaddeleri = [];

      const ids = ctx.state.selectedRation.besinmaddeleri.map((e) => e.nkey);

      ctx.state.selectedRationNutrients = await ctx.dispatch(
        "getRationNutrientsAll",
        { plantid: ctx.state.selectedRation.rasyon[0].plantid, ids }
      );
      ctx.state.allNutrients = ctx.state.selectedRationNutrients;
    },
    async getRationNutrientsAll(ctx, { plantid, ids }) {
      let response = await axios.get("/Nutrients/GetNutrientsforRation", {
        headers: {
          culture: getUserData().cultureinfo,
          plantid: plantid,
          ids: ids.join(","),
        },
      });
      ctx.state.nutrientLoading = false;
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },
    async getRationIngredientsAll(ctx, { plantid, ids }) {
      let response = await axios.get("/Ingredients/GetIngredientsforRation", {
        headers: {
          culture: getUserData().cultureinfo,
          plantid: plantid,
          ids: ids.join(","),
        },
      });
      ctx.state.ingredientLoading = false;
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },
    async solveRationService(ctx, ration) {
      let result = await axios.post(
        "/Rations/SolveRation/",
        JSON.stringify(ration),
        {
          headers: {
            plantid: ration.plantid,
            tur: ration.tur,
          },
        }
      );

      var status = result.data["status"];
      if (status == "OK") {
        return result.data["resultdata"][0];
      } else if (status == "ERROR") {
        var responseRation = result.data["resultdata"][0];
        responseRation.cozdu = false;
        return responseRation;
      } else if (status == "NOFEASIBLE") return result.data["resultdata"][0];

      var responseRation = {};
      responseRation.hammaddeler = ration.hammaddeler;
      responseRation.besinmaddeleri = ration.besinmaddeleri;
      responseRation.cozdu = false;
      return responseRation;
    },
    async saveRationService(ctx, { ration, rationid }) {
      let result = await axios.put(`/Rations/SaveRation/${rationid}`, ration, {
        headers: {
          plantid: ration.plantid,
          tur: ration.tur,
        },
      });
      const status = result.data["status"];
      if (status == "OK") {
        return true;
      } else {
        return false;
      }
    },
    async saveRationAsProductionService(ctx, { ration, rationId }) {
      let result = await axios.put(
        `/Rations/SaveRationAsProduction/${rationId}`,
        ration,
        {
          headers: {
            plantid: ration.plantid,
            tur: ration.tur,
          },
        }
      );
      const status = result.data["status"];
      if (status == "OK") {
        return true;
      } else {
        return false;
      }
    },
    async saveRationForProduction(ctx) {
      const { companyid, name, surname } = getUserData();

      let _newration = {};
      _newration.plantid = ctx.state.selectedRation.rasyon[0].plantid;
      _newration.price = ctx.state.selectedRation.rasyon[0].fiyat;
      _newration.cozdu = false;
      _newration.tur = ctx.state.selectedRation.rasyon[0].tur;
      _newration.addUsername = name + " " + surname;
      _newration.companyid = companyid;
      _newration.minkaba = 0;
      _newration.maxkaba = 0;
      _newration.id = ctx.state.selectedRation.rasyon[0].id;
      _newration.tur = ctx.state.selectedRation.rasyon[0].tur;
      _newration.yemtoplami = 0;
      _newration.rasyonadi = ctx.state.selectedRation.rasyon[0].rasyonadi;
      _newration.addDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
      _newration.hammaddeler = ctx.state.selectedRation.hammaddeler.map((x) => {
        return {
          id: x.id,
          ikey: x.ikey,
          kod: x.kod,
          ingredientname: x.ingredientname,
          karisim: x.karisim,
          min: x.min,
          max: x.max,
          price: x.price,
          selected: x.selected,
          product: x.product,
        };
      });
      _newration.besinmaddeleri = ctx.state.selectedRation.besinmaddeleri.map(
        (x) => {
          return {
            id: x.id,
            kod: x.kod,
            nkey: x.nkey,
            nutrientname: x.nutrientname,
            birim: x.birim,
            deger: x.deger,
            min: x.min,
            max: x.max,
            tip: x.tip,
            selected: x.selected,
          };
        }
      );
      var isSuccess = await ctx.dispatch("saveRationAsProductionService", {
        ration: _newration,
        rationId: ctx.state.selectedRation.rasyon[0].id,
      });
      if (isSuccess) {
        this._vm.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: VueI18n.t("successful"),
            icon: "AlertCircleIcon",
            variant: "success",
            text: VueI18n.t("saved", { type: VueI18n.t("ration") }),
          },
        });
      } else {
        this._vm.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertCircleIcon",
            variant: "danger",
            text: VueI18n.t("notSaved", { type: VueI18n.t("ration") }),
          },
        });
      }
    },
    async saveRation(ctx) {
      let _newration = {};
      _newration.plantid = ctx.state.selectedRation.rasyon[0].plantid;
      _newration.price = ctx.state.selectedRation.rasyon[0].fiyat;
      _newration.cozdu = false;
      _newration.minkaba = 0;
      _newration.maxkaba = 0;
      _newration.tur = ctx.state.selectedRation.rasyon[0].tur;
      _newration.yemtoplami = 0;
      _newration.hammaddeler = ctx.state.selectedRation.hammaddeler.map((x) => {
        delete x.id;
        return {
          ikey: x.ikey,
          kod: x.kod,
          ingredientname: x.ingredientname,
          karisim: x.karisim,
          min: x.min,
          max: x.max,
          price: x.price,
          selected: x.selected,
          product: x.product,
          kodandingredientname: `${x.kod} ${x.ingredientname}`,
          itembgcolor: "green",
          itemtextcolor: "white",
        };
      });
      _newration.besinmaddeleri = ctx.state.selectedRation.besinmaddeleri.map(
        (x) => {
          return {
            id: x.id,
            kod: x.kod,
            nkey: x.nkey,
            nutrientname: x.nutrientname,
            birim: x.birim,
            deger: x.deger,
            min: x.min,
            max: x.max,
            tip: x.tip,
            selected: x.selected,
            kodandnutrientnameandbirim: `${x.kod} ${x.nutrientname} (${x.birim})`,
            itembgcolor: "green",
            itemtextcolor: "white",
          };
        }
      );
      if (
        ctx.state.selectedRation.hammaddeler == null ||
        !ctx.state.selectedRation.hammaddeler.length
      ) {
        this._vm.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: VueI18n.t("successful"),
            icon: "AlertCircleIcon",
            variant: "danger",
            text: VueI18n.t("oneIngredientNeeded"),
          },
        });
        return;
      } else if (
        ctx.state.selectedRation.besinmaddeleri == null ||
        !ctx.state.selectedRation.besinmaddeleri.length
      ) {
        this._vm.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertCircleIcon",
            variant: "danger",
            text: VueI18n.t("oneNutrientNeeded"),
          },
        });
        return;
      }
      var isSuccess = await ctx.dispatch("saveRationService", {
        ration: _newration,
        rationid: ctx.state.selectedRation.rasyon[0].id,
      });
      if (isSuccess) {
        this._vm.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: VueI18n.t("successful"),
            icon: "AlertCircleIcon",
            variant: "success",
            text: VueI18n.t("saved", { type: VueI18n.t("ration") }),
          },
        });
      } else {
        this._vm.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertCircleIcon",
            variant: "danger",
            text: VueI18n.t("notSaved", { type: VueI18n.t("ration") }),
          },
        });
      }
    },
    async solveRation(ctx) {
      let tur = ctx.state.selectedRation.rasyon[0].tur;

      if (
        ctx.state.selectedRation.hammaddeler == null ||
        !ctx.state.selectedRation.hammaddeler.length
      ) {
        this._vm.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: VueI18n.t("successful"),
            icon: "AlertCircleIcon",
            variant: "danger",
            text: VueI18n.t("oneIngredientNeeded"),
          },
        });
      } else {
        if (
          ctx.state.selectedRation.besinmaddeleri == null ||
          !ctx.state.selectedRation.besinmaddeleri.length
        ) {
          this._vm.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: VueI18n.t("unsuccessful"),
              icon: "AlertCircleIcon",
              variant: "danger",
              text: VueI18n.t("oneNutrientNeeded"),
            },
          });
        } else {
          let productexists = false;

          for (
            let i = 0;
            i < ctx.state.selectedRation.hammaddeler.length;
            i++
          ) {
            if (ctx.state.selectedRation.hammaddeler[i].product) {
              productexists = true;
            }
          }
          let forceProduct = getUserData().forceproduct;
          if (tur != "Mix" && forceProduct && !productexists) {
            this._vm.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: VueI18n.t("unsuccessful"),
                icon: "AlertCircleIcon",
                variant: "danger",
                text: VueI18n.t("oneProductNeeded"),
              },
            });
          } else {
            var tinyRation = {};
            tinyRation.hammaddeler = [];
            tinyRation.besinmaddeleri = [];
            tinyRation.cozdu = false;
            tinyRation.price = 0;
            tinyRation.plantid = ctx.state.selectedRation.rasyon[0].plantid;
            tinyRation.yemtoplami = 0;
            tinyRation.minkaba = ctx.state.selectedRation.rasyon[0].minkaba;
            tinyRation.maxkaba = ctx.state.selectedRation.rasyon[0].maxkaba;
            tinyRation.hammaddeler = ctx.state.selectedRation.hammaddeler.map(
              (x) => {
                return {
                  ...x,
                  karisim: parseFloat(x.karisim)
                    .toFixed(getUserData().ingredientdecimal)
                    .replace(".", ","),
                  min: x.min === "" ? 0 : x.min.toString().replace(",", "."),
                  max: x.max === "" ? 0 : x.max.toString().replace(",", "."),
                  price:
                    x.price === "" ? 0 : x.price.toString().replace(",", "."),
                };
              }
            );

            tinyRation.besinmaddeleri =
              ctx.state.selectedRation.besinmaddeleri.map((x) => {
                return {
                  ...x,
                  deger: parseFloat(x.deger)
                    .toFixed(getUserData().nutrientdecimal)
                    .replace(".", ","),

                  min: (x.min == "") === "" ? 0 : parseFloat(x.min),
                  max: x.max === "" ? 0 : parseFloat(x.max),
                };
              });
            tinyRation.tur = tur;

            let response = await ctx.dispatch("solveRationService", tinyRation);
            tinyRation = response;
            ctx.state.cozdu = tinyRation.cozdu;
            ctx.state.selectedRation.cozdu = tinyRation.cozdu;
            ctx.state.selectedRation.rasyon[0].fiyat = tinyRation.price;
            ctx.state.selectedRation.hammaddeler = tinyRation.hammaddeler;
            ctx.state.selectedRation.besinmaddeleri = tinyRation.besinmaddeleri;

            for (
              let i = 0;
              i < ctx.state.selectedRation.hammaddeler.length;
              i++
            ) {
              var ingredientPrecision = "0.";
              for (let i = 0; i < getUserData().ingredientdecimal; i++) {
                ingredientPrecision += "9";
              }

              var nutrientPrecision = "0.";
              for (let i = 0; i < getUserData().nutrientdecimal; i++) {
                nutrientPrecision += "9";
              }
              if (
                (tinyRation.hammaddeler[i].max != 0 &&
                  ctx.state.selectedRation.hammaddeler[i].karisim >=
                    ctx.state.selectedRation.hammaddeler[i].min -
                      parseFloat(nutrientPrecision) &&
                  ctx.state.selectedRation.hammaddeler[i].karisim <=
                    ctx.state.selectedRation.hammaddeler[i].max +
                      parseFloat(nutrientPrecision)) ||
                (ctx.state.selectedRation.hammaddeler[i].max == 0 &&
                  ctx.state.selectedRation.hammaddeler[i].karisim >=
                    ctx.state.selectedRation.hammaddeler[i].min -
                      parseFloat(nutrientPrecision))
              ) {
                ctx.state.selectedRation.hammaddeler[i].itemtextcolor = "Green";
                ctx.state.selectedRation.hammaddeler[i].itembgcolor = "White";
              } else {
                ctx.state.selectedRation.hammaddeler[i].itemtextcolor = "Red";
                ctx.state.selectedRation.hammaddeler[i].itembgcolor = "White";
              }
            }

            for (
              let i = 0;
              i < ctx.state.selectedRation.besinmaddeleri.length;
              i++
            ) {
              ctx.state.selectedRation.besinmaddeleri[i].deger =
                ctx.state.selectedRation.besinmaddeleri[i].deger ?? 0;
              ctx.state.selectedRation.besinmaddeleri[i].max =
                ctx.state.selectedRation.besinmaddeleri[i].max ?? 0;
              ctx.state.selectedRation.besinmaddeleri[i].min =
                ctx.state.selectedRation.besinmaddeleri[i].min ?? 0;

              if (
                (ctx.state.selectedRation.besinmaddeleri[i].max != 0 &&
                  ctx.state.selectedRation.besinmaddeleri[i].deger >=
                    ctx.state.selectedRation.besinmaddeleri[i].min -
                      parseFloat(ingredientPrecision) &&
                  ctx.state.selectedRation.besinmaddeleri[i].deger <=
                    ctx.state.selectedRation.besinmaddeleri[i].max +
                      parseFloat(ingredientPrecision)) ||
                (ctx.state.selectedRation.besinmaddeleri[i].max == 0 &&
                  ctx.state.selectedRation.besinmaddeleri[i].deger >=
                    ctx.state.selectedRation.besinmaddeleri[i].min -
                      parseFloat(ingredientPrecision))
              ) {
                ctx.state.selectedRation.besinmaddeleri[i].itemtextcolor =
                  "Green";
                ctx.state.selectedRation.besinmaddeleri[i].itembgcolor =
                  "White";
              } else {
                ctx.state.selectedRation.besinmaddeleri[i].itemtextcolor =
                  "Red";
                ctx.state.selectedRation.besinmaddeleri[i].itembgcolor =
                  "White";
              }
            }
          }

          if (ctx.state.selectedRation.cozdu) {
            this._vm.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: VueI18n.t("successful"),
                icon: "AlertCircleIcon",
                variant: "success",
                text: VueI18n.t("solved"),
              },
            });
          } else {
            this._vm.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: VueI18n.t("unsuccessful"),
                icon: "AlertCircleIcon",
                variant: "danger",
                text: VueI18n.t("notSolved"),
              },
            });
          }
        }
      }
    },
  },
};
