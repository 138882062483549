/* eslint-disable prettier/prettier */
export default [
    {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/account-setting/Profile.vue"),
        meta: {
            action: "read",
            resource: "Auth",

            pageTitle: "profile",
          
        },
    },
    {
        path: "/users",
        name: "users",
        component: () => import("@/views/company/Users/Users.vue"),
        meta: {
            action: "read",
            resource: "Auth",

            pageTitle: "users",
            breadcrumb: [
                   {
                    text: "generalSettings",
                },
                {
                    text: "users",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/user/:id",
        name: "UserDetail",
        component: () => import("@/views/company/Users/UserView.vue"),
        exact: true,
        meta: {
            action: "read",
            resource: "Auth",
            navActiveLink: "users",

            pageTitle: "userInfo",
            breadcrumb: [
                {
                    text: "generalSettings",
                },
                {
                text: "users",
                to: "/users",

                },
                {
                    text: "userInfo",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/user/edit/:id",
        name: "UserEdit",
        component: () => import("@/views/company/Users/UserEdit.vue"),
        exact: true,
        meta: {
            action: "read",
            resource: "Auth",
            navActiveLink: "users",

            pageTitle: "user",
            breadcrumb: [
                {
                    text: "generalSettings",
                    },
                    {
                    text: "users",
                    to: "/users",

                    },
                {
                    text: "user",
                    active: true,
                },
            ],
        },
    },
];
