import { $themeBreakpoints } from "@themeConfig";
import ability from "@/libs/acl/ability";
import { getUserData } from "@/auth/utils";
import store from "@/store";
export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    pictureUrl: "",
    selectedPlantId: 0,
    selectetPlantType: 1,
    selectedPlantName: "",
    companyPictureUrl: "",
    companyCredits: 0,
    errCount: 0,
    firstRequest: true,
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return "xl";
      if (windowWidth >= $themeBreakpoints.lg) return "lg";
      if (windowWidth >= $themeBreakpoints.md) return "md";
      if (windowWidth >= $themeBreakpoints.sm) return "sm";
      return "xs";
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay =
        val !== undefined ? val : !state.shallShowOverlay;
    },
    UPDATE_COMPANY_PICTURE_URL(state, val) {
      state.companyPictureUrl = val;
    },
    UPDATE_PICTURE_URL(state, val) {
      state.pictureUrl = val;
    },
    UPDATE_PLANT(state, val) {
      store.dispatch("plantsModule/fetchPlantMilkingTimes");
      state.selectedPlantId = val;
    },
    UPDATE_PLANT_TYPE(state, val) {
      state.selectetPlantType = val.planttype;
      var plantType = val.planttype == 1 ? "Dairy" : "Beef";
      var reversedPlantType = val.planttype == 1 ? "Beef" : "Dairy";
      var user = getUserData();
      var currentAbility = user.ability.filter(
        (a) => a.subject != reversedPlantType
      );
      currentAbility.push({
        subject: plantType,
        action: "read",
      });
      user.ability = currentAbility;
      localStorage.setItem("userData", JSON.stringify(user));
      ability.update(currentAbility);
    },
    UPDATE_COMPANY_CREDITS(state, val) {
      state.companyCredits = val;
    },
    SET_ERROR(state, val) {
      state.errCount = val;
    },
    SET_FIRST_REQUEST(state, val) {
      state.firstRequest = val;
    },
  },
  actions: {
    setError(ctx, errCount) {
      ctx.commit("SET_ERROR", errCount);
    },
    setFirstRequest(ctx, firstRequest) {
      ctx.commit("SET_FIRST_REQUEST", firstRequest);
    },
  },
};
