import axios from "@axios";
import VueI18n from "@/libs/i18n";

export default {
  async fetchIllnesses(ctx, plantid) {
    var response = await axios.get("/Definitions/GetIllnesses", {
      headers: {
        plantid,
      },
    });
    if (response.data.status != "OK") return null;
    ctx.commit("SET_ILLNESSES", response.data.resultdata);

    return response.data.resultdata;
  },

  async addIllness(ctx, illness) {
    var response = await axios.post("/Definitions/AddIllness/", illness);
    return response.data.status == "OK";
  },
  async updateIllness(ctx, illness) {
    var response = await axios.put(
      `/Definitions/UpdateIllness/${illness.id}`,
      illness
    );

    return response.data.status == "OK";
  },
  async deleteIllness(ctx, illnessid) {
    var response = {};
    return await this._vm
      .$swal({
        title: VueI18n.t("areYouSure"),
        text: VueI18n.t("notRevert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: VueI18n.t("yes"),
        cancelButtonText: VueI18n.t("no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
      .then(async (result) => {
        if (result.value) {
          var response = await axios.delete(
            `/Definitions/DeleteIllness/${illnessid}`
          );
          return response.data.status == "OK";
        } else return false;
      });
  },
  async fetchIllnessType(ctx, plantid) {
    var response = await axios.get("/Definitions/GetIllnessTypes/");
    if (response.data.status != "OK") return null;

    return response.data.resultdata;
  },

  async addIllnessType(ctx, illnessType) {
    var response = await axios.post(
      "/Definitions/AddIllnessType/",
      illnessType
    );
    return response.data.status == "OK";
  },
  async updateIllnessType(ctx, illnessType) {
    var response = await axios.put(
      `/Definitions/UpdateIllnessType/${illnessType.id}`,
      illnessType
    );

    return response.data.status == "OK";
  },
  async deleteIllnessType(ctx, illnessTypeid) {
    var response = {};
    return await this._vm
      .$swal({
        title: VueI18n.t("areYouSure"),
        text: VueI18n.t("notRevert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: VueI18n.t("yes"),
        cancelButtonText: VueI18n.t("no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
      .then(async (result) => {
        if (result.value) {
          var response = await axios.delete(
            `/Definitions/DeleteIllnessType/${illnessTypeid}`
          );
          return response.data.status == "OK";
        } else return false;
      });
  },
};
