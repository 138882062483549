import axios from "@axios";
import VueI18n from "@/libs/i18n";

export default {
  async fetchPadoks(ctx, { plantid, barnid }) {
    var response = await axios.get("/Definitions/GetPadoks", {
      headers: {
        plantid,
        barnid,
      },
    });
    if (response.data.status != "OK") return null;
    ctx.commit("SET_PADOKS", response.data.resultdata);

    return response.data.resultdata;
  },

  async addPadok(ctx, padok) {
    var response = await axios.post("/Definitions/AddPadok/", padok, {
      headers: {
        plantid: padok.plantid,
        barnid: padok.barnid,
      },
    });
    return response.data.status == "OK";
  },
  async updatePadok(ctx, padok) {
    var response = await axios.put(
      `/Definitions/UpdatePadok/${padok.id}`,
      padok
    );

    return response.data.status == "OK";
  },
  async deletePadok(ctx, padokid) {
    var response = {};
    return await this._vm
      .$swal({
        title: VueI18n.t("areYouSure"),
        text: VueI18n.t("notRevert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: VueI18n.t("yes"),
        cancelButtonText: VueI18n.t("no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
      .then(async (result) => {
        if (result.value == true) {
          var response = await axios.delete(
            `/Definitions/DeletePadok/${padokid}`
          );
          return response.data.status == "OK";
        } else throw new Error("cancelled");
      });
  },
};
