import axios from "@axios";
import VueI18n from "@/libs/i18n";

export default {
  async fetchVets(ctx, plantid) {
    var response = await axios.get("/Definitions/GetPlantVets", {
      headers: {
        plantid,
      },
    });
    if (response.data.status != "OK") return null;
    ctx.commit("SET_VETS", response.data.resultdata);

    return response.data.resultdata;
  },

  async addVet(ctx, { vet, plantid }) {
    var response = await axios.post("/Definitions/AddCompanyVet/", vet, {
      headers: {
        plantid,
      },
    });
    return response.data.status == "OK";
  },
  async updateVet(ctx, vet) {
    var response = await axios.put(`/Definitions/UpdateVet/${vet.id}`, vet);

    return response.data.status == "OK";
  },
  async deleteVet(ctx, vetid) {
    var response = {};
    return await this._vm
      .$swal({
        title: VueI18n.t("areYouSure"),
        text: VueI18n.t("notRevert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: VueI18n.t("yes"),
        cancelButtonText: VueI18n.t("no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
      .then(async (result) => {
        if (result.value) {
          var response = await axios.delete(`/Definitions/DeleteVet/${vetid}`);
          return response.data.status == "OK";
        } else return false;
      });
  },
};
