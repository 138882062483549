/* eslint-disable prettier/prettier */
export default [
  {
    path: "/animals/",
    redirect: (_to) => {
      return {
        path: `/animals/actions`,
      };
    },
    name: "animals",
    component: () => import("@/views/Plants/Animals/Animals.vue"),
    meta: {
      action: "read",
      resource: "Auth",
      pageTitle: "animals",
      breadcrumb: [
        {
          text: "animals",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "/animals/actions",
        name: "animal-actions",
        components: {
          nestedView: () =>
            import("@/views/Plants/Animals/components/AnimalActions.vue"),
        },
        meta: {
          title: "animalActions",
        },
      },

      {
        path: "/animals/actions/milk/",
        name: "animal-milk",
        components: {
          nestedView: () => import("@/views/Plants/Animals/AnimalMilkList.vue"),
        },
        meta: {
          title: "animalMilking",
        },
      },
      {
        path: "/animals/actions/weights-conditions/",
        name: "animal-weights-conditions",
        components: {
          nestedView: () =>
            import("@/views/Plants/Animals/AnimalConditionsList.vue"),
        },
        meta: {
          title: "animalWeightsConditions",
        },
      },
      {
        path: "/animals/actions/insemination/",
        name: "animal-insemination",
        components: {
          nestedView: () =>
            import("@/views/Plants/Animals/AnimalInsemininationList.vue"),
        },
        meta: {
          title: "animalnsesmination",
        },
      },
      {
        path: "/animals/actions/birth/",
        name: "animal-birth",
        components: {
          nestedView: () => import("@/views/Plants/Animals/AnimalBirhList.vue"),
        },
        meta: {
          title: "animalBirth",
        },
      },
      {
        path: "/animals/actions/exam/",
        name: "animal-exam",
        components: {
          nestedView: () => import("@/views/Plants/Animals/AnimalExamList.vue"),
        },
        meta: {
          title: "animalExam",
        },
      },
      {
        path: "/animals/actions/medicine/",
        name: "animal-medicine",
        components: {
          nestedView: () =>
            import("@/views/Plants/Animals/AnimalMedicineList.vue"),
        },
        meta: {
          title: "animalMedicine",
        },
      },
      {
        path: "/animals/actions/status/",
        name: "animal-status",
        components: {
          nestedView: () =>
            import("@/views/Plants/Animals/AnimalStatusList.vue"),
        },
        meta: {
          title: "animalStatus",
        },
      },
      {
        path: "/animals/actions/mobility/",
        name: "animal-mobility",
        components: {
          nestedView: () =>
            import("@/views/Plants/Animals/AnimalMobilityList.vue"),
        },
        meta: {
          title: "mobilities",
          action: "read",
          resource: "Auth",

          pageTitle: "mobility",
          breadcrumb: [
            {
              text: "mobility",
              active: true,
            },
          ],
        },
      },
      {
        path: "/animals/actions/group/",
        name: "animal-group",
        components: {
          nestedView: () => import("@/views/Plants/Animals/AnimalGroups.vue"),
        },
        meta: {
          title: "animalGroups",
        },
      },
      {
        path: "/animals/actions/feeding/",
        name: "animal-feeding",
        components: {
          nestedView: () => import("@/views/Plants/Animals/AnimalFeedList.vue"),
        },
        meta: {
          title: "animalFeedings",
        },
      },
    ],
  },

  {
    path: "/animal/slaughterhouses",
    name: "slaughterhouses",
    component: () => import("@/views/Plants/Animals/AnimalSlaughterhouses.vue"),
    exact: true,
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "slaughterhouses",
      breadcrumb: [
        {
          text: "slaughterhouses",
          active: true,
        },
      ],
    },
  },
  {
    path: "/animal/feeding",
    name: "animals-feeding",
    component: () => import("@/views/Plants/Animals/FeedingList/FeedList.vue"),
    exact: true,
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "feeding",
      breadcrumb: [
        {
          text: "feeding",
          active: true,
        },
      ],
    },
  },
  {
    path: "/animal/milking",
    name: "animals-milking",
    component: () =>
      import("@/views/Plants/Animals/MilkingsList/MilkingList.vue"),
    exact: true,
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "milking",
      breadcrumb: [
        {
          text: "milking",
          active: true,
        },
      ],
    },
  },
  {
    path: "/animal/weights-conditions",
    name: "animals-weights-conditions",
    component: () => import("@/views/Plants/Animals/WeightList/WeightList.vue"),
    exact: true,
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "weightsConditions",
      breadcrumb: [
        {
          text: "weightsConditions",
          active: true,
        },
      ],
    },
  },
];
