import { getUserData } from "@/auth/utils";
import axios from "@axios";
import VueI18n from "@/libs/i18n";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchMachines(ctx, plantid) {
      var response = await axios.get(`/Machines/GetMachines`, {
        headers: { culture: getUserData().cultureinfo, plantid },
      });
      if (response.data.status != "OK") return null;

      return response.data.resultdata;
    },
    async fetchMachine(ctx, { plantid, machineid }) {
      var response = await axios.get(`/Machines/GetMachine/${machineid}`);
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },

    async fetchMachineTypes(ctx, plantid) {
      var response = await axios.get(`/Machines/GetMachineTypes`);
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },

    async deleteMachine(ctx, machine) {
      var response = {};
      return await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            var response = await axios.delete(
              `/Machines/DeleteMachine/${machine}`
            );
            return response.data.status == "OK";
          } else return false;
        });
    },
    async updateMachine(ctx, machine) {
      var response = await axios.put(
        `/Machines/UpdateMachine/${machine.id}`,
        machine
      );
      return response.data.status == "OK";
    },
    async addMachine(ctx, machine) {
      var response = await axios.post(`/Machines/AddMachine`, machine);
      return response.data.status == "OK";
    },
  },
};
