import axios from "@axios";
import VueI18n from "@/libs/i18n";
import store from "@/store";
export default {
  async fetchMedicines(ctx, plantid) {
    var response = await axios.get("/Definitions/GetMedicines", {
      headers: { plantid: store.state.app.selectedPlantId },
    });
    if (response.data.status != "OK") return null;
    ctx.commit("SET_MEDICINES", response.data.resultdata);

    return response.data.resultdata;
  },

  async fetchVendorMedicines(ctx) {
    var response = await axios.get("/Definitions/GetVendorMedicines");
    if (response.data.status != "OK") return null;
    ctx.commit("SET_MEDICINES", response.data.resultdata);

    return response.data.resultdata;
  },

  async fetchMedicinePurchases(ctx, id) {
    var response = await axios.get("/Definitions/GetMedicinePurchases/" + id, {
      headers: { plantid: store.state.app.selectedPlantId },
    });
    if (response.data.status != "OK") return null;
    return response.data.resultdata;
  },

  async addMedicinePurchase(ctx, medicine) {
    var response = await axios.post(
      "/Definitions/AddMedicinePurchase",
      medicine,
      {
        headers: { plantid: store.state.app.selectedPlantId },
      }
    );
    return response.data.status == "OK";
  },
  async updateMedicinePurchase(ctx, medicine) {
    var response = await axios.put(
      `/Definitions/UpdateMedicinePurchase/0`,
      medicine
    );

    return response.data.status == "OK";
  },
  async deleteMedicinePurchase(ctx, medicineid) {
    var response = {};
    return await this._vm
      .$swal({
        title: VueI18n.t("areYouSure"),
        text: VueI18n.t("notRevert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: VueI18n.t("yes"),
        cancelButtonText: VueI18n.t("no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
      .then(async (result) => {
        if (result.value) {
          var response = await axios.delete(
            `/Definitions/DeleteMedicinePurchase/${medicineid}`
          );
          return response.data.status == "OK";
        } else return false;
      });
  },
  async fetchMedicines(ctx, plantid) {
    var response = await axios.get("/Definitions/GetMedicines", {
      headers: { plantid: store.state.app.selectedPlantId },
    });
    if (response.data.status != "OK") return null;
    ctx.commit("SET_MEDICINES", response.data.resultdata);

    return response.data.resultdata;
  },

  async addMedicine(ctx, medicine) {
    var response = await axios.post("/Definitions/AddMedicine", medicine, {
      headers: { plantid: store.state.app.selectedPlantId },
    });
    return response.data.status == "OK";
  },
  async updateMedicine(ctx, medicine) {
    var response = await axios.put(
      `/Definitions/UpdateMedicine/${medicine.id}`,
      medicine
    );

    return response.data.status == "OK";
  },
  async deleteMedicine(ctx, medicineid) {
    var response = {};
    return await this._vm
      .$swal({
        title: VueI18n.t("areYouSure"),
        text: VueI18n.t("notRevert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: VueI18n.t("yes"),
        cancelButtonText: VueI18n.t("no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
      .then(async (result) => {
        if (result.value) {
          var response = await axios.delete(
            `/Definitions/DeleteMedicine/${medicineid}`
          );
          return response.data.status == "OK";
        } else return false;
      });
  },
};
