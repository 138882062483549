import axios from "@axios";
import spermas from "./definition/spermas";
import treatments from "./definition/treatments";
import vaccine from "./definition/vaccine";
import notes from "./definition/notes";
import exams from "./definition/exams";
import vets from "./definition/vets";
import medicines from "./definition/medicines";
import illnesses from "./definition/illnesses";
import barns from "./definition/barns";
import parameters from "./definition/parameters";
import padoks from "./definition/padoks";
import synchronizations from "./definition/synchronizations";
export default {
  namespaced: true,
  state: {
    treatmentData: [],
    selectedTreatment: {},
    treatmentTypesData: [],
    selectedTreatmentType: {},
    spermaData: [],
    selectedSperma: {},
    vaccineData: [],
    selectedVaccine: {},
    noteData: [],
    selectedNote: {},
    examData: [],
    selectedExam: {},
    vetData: [],
    selectedVet: {},
    medicineData: [],
    selectedMedicine: {},
    illnessData: [],
    selectedIllness: {},
    barnData: [],
    selectedBarn: {},
    parameterData: [],
    selectedParameter: {},
    problemData: [],
    birthTypeData: [],
    padokData: [],
    synchronizationData: [],
    selectedSynchronization: {},
    statusData: [],
    vaccineData: [],
  },
  getters: {},
  mutations: {
    SET_PADOKS(state, payload) {
      state.padokData = payload;
    },
    SET_PROBLEMS(state, payload) {
      state.problemData = payload;
    },
    SET_BIRTH_TYPES(state, payload) {
      state.birthTypeData = payload;
    },
    SET_TREATMENTS(state, payload) {
      state.treatmentData = payload;
    },
    SET_TREATMENT(state, payload) {
      state.selectedTreatment = payload;
    },
    SET_TREATMENT_TYPES(state, payload) {
      state.treatmentTypesData = payload;
    },
    SET_TREATMENT_TYPE(state, payload) {
      state.selectedTreatmentType = payload;
    },
    SET_SPERMAS(state, payload) {
      state.spermaData = payload;
    },
    SET_SPERMA(state, payload) {
      state.selectedSperma = payload;
    },
    SET_VACCINES(state, payload) {
      state.vaccineData = payload;
    },
    SET_VACCINE(state, payload) {
      state.selectedVaccine = payload;
    },
    SET_NOTES(state, payload) {
      state.noteData = payload;
    },
    SET_NOTE(state, payload) {
      state.selectedNote = payload;
    },
    SET_EXAMS(state, payload) {
      state.examData = payload;
    },
    SET_EXAM(state, payload) {
      state.selectedExam = payload;
    },
    SET_MEDICINES(state, payload) {
      state.medicineData = payload;
    },
    SET_VACCINES(state, payload) {
      state.vaccineData = payload;
    },
    SET_MEDICINE(state, payload) {
      state.selectedMedicine = payload;
    },
    SET_ILLNESSES(state, payload) {
      state.illnessData = payload;
    },
    SET_ILLNESS(state, payload) {
      state.selectedMedicine = payload;
    },
    SET_BARNS(state, payload) {
      state.barnData = payload;
    },
    SET_BARN(state, payload) {
      state.selectedMedicine = payload;
    },
    SET_PARAMETERS(state, payload) {
      state.parameterData = payload;
    },
    SET_PARAMETER(state, payload) {
      state.selectedParameter = payload;
    },
    SET_VETS(state, payload) {
      state.vetData = payload;
    },
    SET_VET(state, payload) {
      state.selectedVet = payload;
    },
    SET_SYNCHRONIZATIONS(state, payload) {
      state.synchronizationData = payload;
    },
    SET_SYNCHRONIZATION(state, payload) {
      state.selectedSynchronization = payload;
    },
    SET_STATUSES(state, payload) {
      state.statusData = payload;
    },
  },
  actions: {
    ...spermas,
    ...treatments,
    ...vaccine,
    ...notes,
    ...exams,
    ...vets,
    ...medicines,
    ...illnesses,
    ...barns,
    ...parameters,
    ...padoks,
    ...synchronizations,
    async fetchBirthTypes(ctx, plantid) {
      var response = await axios.get("/Definitions/GetBirthTypes", {
        headers: {
          plantid,
        },
      });
      if (response.data.status != "OK") return null;
      ctx.commit("SET_BIRTH_TYPES", response.data.resultdata);

      return response.data.resultdata;
    },
    async fetchBirthProblems(ctx, plantid) {
      var response = await axios.get("/Definitions/GetBirthProblems", {
        headers: {
          plantid,
        },
      });
      if (response.data.status != "OK") return null;
      ctx.commit("SET_PROBLEMS", response.data.resultdata);

      return response.data.resultdata;
    },

    async fetchStatuses(ctx, plantid) {
      const response = await axios.get("/Animals/Status");
      ctx.commit("SET_STATUSES", response.data);

      return response.data;
    },
  },
};
