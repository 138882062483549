import axios from "@axios";
import VueI18n from "@/libs/i18n";

export default {
  async fetchAnimalFeeds(ctx, animalId) {
    var response = await axios.get(`/Rations/GetAnimalFeeds/${animalId}`);
    if (response.data.status != "OK") return null;
    ctx.commit("SET_ANIMAL_FEEDS", response.data.resultdata);
    return response.data.resultdata;
  },

  async fetchPlantAnimalFeeds(ctx, plantId) {
    var response = await axios.get(`/Rations/GetPlantAnimalFeeds/`, {
      headers: {
        plantid: plantId,
      },
    });
    if (response.data.status != "OK") return null;
    ctx.commit("SET_PLANT_ANIMAL_FEEDS", response.data.resultdata);
    return response.data.resultdata;
  },

  async addAnimalFeed(ctx, animalFeed) {
    var response = await axios.post(`/Rations/AddAnimalGroupFeed`, animalFeed);

    return response.data.status == "OK";
  },

  async updateAnimalFeed(ctx, animalFeed) {
    var response = await axios.put(
      `/Rations/UpdateAnimalGroupFeed/${animalFeed.id}`,
      animalFeed
    );
    return response.data.status == "OK";
  },

  async deleteAnimalFeed(ctx, examid) {
    var response = {};
    return await this._vm
      .$swal({
        title: VueI18n.t("areYouSure"),
        text: VueI18n.t("notRevert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: VueI18n.t("yes"),
        cancelButtonText: VueI18n.t("no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
      .then(async (result) => {
        if (result.value) {
          var response = await axios.delete(
            `/Rations/DeleteAnimalGroupFeed/${examid}`
          );
          return response.data.status == "OK";
        } else return false;
      });
  },
};
