/* eslint-disable prettier/prettier */
export default [
  {
    path: "/definitions/machines/",
    name: "machines",
    component: () => import("@/views/Machines/Machines.vue"),
    meta: {
      action: "read",
      resource: "Auth",
      pageTitle: "machines",
      breadcrumb: [
        {
          text: "definitions",
          active: false,
          to: "/definitions/",
        },
        {
          text: "machines",
          active: true,
        },
      ],
    },
  },
  {
    path: "/definitions/machine/:machineid",
    name: "machine-detail",
    component: () => import("@/views/Machines/Machines.vue"),
    exact: true,
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "machineDetail",
      breadcrumb: [
        {
          text: "machineDetail",
          active: true,
        },
      ],
    },
  },
];
