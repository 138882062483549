/* eslint-disable prettier/prettier */
export default [
  {
    path: "/vets",
    name: "vets",
    component: () => import("@/views/Vets/Vets.vue"),
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "vets",
      breadcrumb: [
        {
          text: "companySettings",
        },
        {
          text: "vets",
          active: true,
        },
      ],
    },
  },
];
