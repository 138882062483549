/* eslint-disable prettier/prettier */
export default [
  
    {
        path: "/modules",
        name: "modules",
        component: () => import("@/views/company/Modules/Modules.vue"),
        meta: {
            action: "read",
            resource: "Auth",

            pageTitle: "modules",
            breadcrumb: [
                   {
                    text: "comppanySettings",
                },
                {
                    text: "modules",
                    active: true,
                },
            ],
        },
    },
    
 
];
