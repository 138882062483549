/* eslint-disable prettier/prettier */
export default [
  {
    path: "/definitions",
    name: "definitions",
    component: () =>
      import(
        /* webpackChunkName: "definitions" */ "@/views/Definitions/Definitions.vue"
      ),
    meta: {
      action: "read",
      resource: "Auth",
      pageTitle: "definitions",
      breadcrumb: [
        {
          text: "definitions",
          active: true,
        },
      ],
    },
  },
  {
    path: "/definitions/treatments",
    name: "treatments",
    component: () => import("@/views/Treatments/Treatments.vue"),
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "definitions",

      pageTitle: "treatments",
      breadcrumb: [
        {
          text: "definitions",
          active: false,
          to: "/definitions",
        },
        {
          text: "treatments",
          active: true,
        },
      ],
    },
  },
  {
    path: "/definitions/treatment-types",
    name: "treatmentTypes",
    component: () => import("@/views/Treatments/TreatmentTypes.vue"),
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "definitions",

      pageTitle: "treatmentTypes",
      breadcrumb: [
        {
          text: "definitions",
          active: false,
          to: "/definitions",
        },
        {
          text: "treatmentTypes",
          active: true,
        },
      ],
    },
  },
  {
    path: "/definitions/spermas",
    name: "spermas",
    component: () => import("@/views/Spermas/Spermas.vue"),
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "definitions",

      pageTitle: "spermas",
      breadcrumb: [
        {
          text: "definitions",
          active: false,
          to: "/definitions",
        },
        {
          text: "spermas",
          active: true,
        },
      ],
    },
  },
  {
    path: "/definitions/vaccines",
    name: "vaccines",
    component: () => import("@/views/Vaccines/Vaccines.vue"),
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "definitions",

      pageTitle: "vaccines",
      breadcrumb: [
        {
          text: "definitions",
          active: false,
          to: "/definitions",
        },
        {
          text: "vaccines",
          active: true,
        },
      ],
    },
  },
  {
    path: "/notes",
    name: "notes",
    component: () => import("@/views/Notes/Notes.vue"),
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "notes",

      pageTitle: "notes",
      breadcrumb: [
        {
          text: "definitions",
          active: false,
          to: "/definitions",
        },
        {
          text: "notes",
          active: true,
        },
      ],
    },
  },
  {
    path: "/definitions/exams",
    name: "exams",
    component: () => import("@/views/Exams/Exams.vue"),
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "definitions",

      pageTitle: "exams",
      breadcrumb: [
        {
          text: "definitions",
          active: false,
          to: "/definitions",
        },
        {
          text: "exams",
          active: true,
        },
      ],
    },
  },
  {
    path: "/definitions/medicines",
    name: "medicines",
    component: () => import("@/views/Medicines/Medicines.vue"),
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "definitions",

      pageTitle: "medicinesAndVaccines",
      breadcrumb: [
        {
          text: "definitions",
          active: false,
          to: "/definitions",
        },
        {
          text: "medicinesAndVaccines",
          active: true,
        },
      ],
    },
  },
  {
    path: "/definitions/illnesses",
    name: "illnesses",
    component: () => import("@/views/Illness/Illness.vue"),
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "definitions",

      pageTitle: "illnesses",
      breadcrumb: [
        {
          text: "definitions",
          active: false,
          to: "/definitions",
        },
        {
          text: "illnesses",
          active: true,
        },
      ],
    },
  },
  {
    path: "/definitions/illnesses-types",
    name: "illnessesTypes",
    component: () => import("@/views/Illness/IllnessType.vue"),
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "definitions",

      pageTitle: "illnessesTypes",
      breadcrumb: [
        {
          text: "definitions",
          active: false,
          to: "/definitions",
        },
        {
          text: "illnessesTypes",
          active: true,
        },
      ],
    },
  },
  {
    path: "/definitions/barns",
    name: "barns",
    component: () => import("@/views/Barns/Barns.vue"),
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "definitions",

      pageTitle: "barns",
      breadcrumb: [
        {
          text: "definitions",
          active: false,
          to: "/definitions",
        },
        {
          text: "barns",
          active: true,
        },
      ],
    },
  },
  // parameters route
  {
    path: "/definitions/parameters",
    name: "parameters",
    component: () => import("@/views/Parameters/Parameters.vue"),
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "definitions",

      pageTitle: "parameters",
      breadcrumb: [
        {
          text: "definitions",
          active: false,
          to: "/definitions",
        },
        {
          text: "parameters",
          active: true,
        },
      ],
    },
  },
  {
    path: "/definitions/synchronization",
    name: "synchronizations",
    component: () => import("@/views/Synchronizations/Synchronizations.vue"),
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "definitions",

      pageTitle: "synchronizations",
      breadcrumb: [
        {
          text: "definitions",
          active: false,
          to: "/definitions",
        },
        {
          text: "synchronizations",
          active: true,
        },
      ],
    },
  },
  {
    path: "/definitions/animal-group",
    name: "animals-group",
    component: () => import("@/views/AnimalGroupList/GroupList.vue"),
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "definitions",

      pageTitle: "animalGroup",
      breadcrumb: [
        {
          text: "definitions",
          active: false,
          to: "/definitions",
        },
        {
          text: "animalGroup",
          active: true,
        },
      ],
    },
  },
];
