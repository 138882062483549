/* eslint-disable prettier/prettier */
export default [
  {
    path: "/plants",
    name: "plants",
    component: () => import("@/views/Plants/PlantList.vue"),
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "plants",
      breadcrumb: [
        {
          text: "plants",
          active: true,
        },
      ],
    },
  },
  {
    path: "/plant/:id/detail",
    name: "PlantDetail",
    component: () => import("@/views/Plants/PlantView/PlantView.vue"),
    exact: true,
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "plants",

      pageTitle: "plant",
      breadcrumb: [],
    },
  },
  {
    path: "/plant/visits/",
    name: "plantVisits",
    component: () => import("@/views/Plants/PlantVisits.vue"),
    exact: true,
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "plantVisits",
      breadcrumb: [
        {
          to: "/plants",
          text: "Plants",
        },
        {
          to: ":id",
          text: ":id",
        },
        {
          text: "Visits",
          active: true,
        },
      ],
    },
  },

  {
    path: "/plant/:plantid/orders/:id",
    name: "plantOrderPreview",
    component: () =>
      import("@/views/Plants/Orders/order-preview/orderPreview.vue"),
    exact: true,
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "plants",

      pageTitle: "orderDetails",
      breadcrumb: [
        {
          to: "/plants",
          text: "Plants",
        },
        {
          to: ":id",
          text: ":id",
        },
      ],
    },
  },
  {
    path: "/plant/orders",
    name: "plantOrders",
    component: () => import("@/views/Plants/Orders/OrderList/OrderList.vue"),
    exact: true,
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "Plant Detail",
      breadcrumb: [
        {
          to: "/plants",
          text: "Plants",
        },
        {
          to: ":id",
          text: ":id",
        },
      ],
    },
  },
  {
    path: "/plant/:id/order/add-order",
    name: "addOrder",
    component: () => import("@/views/Plants/Orders/order-add/orderAdd.vue"),
    exact: true,
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "plants",

      pageTitle: "newOrder",
      breadcrumb: [
        {
          to: "/plants",
          text: "Plants",
        },
        {
          to: ":id",
          text: ":id",
        },
      ],
    },
  },
  {
    path: "/ration/ingredients",
    name: "ingredients",
    component: () => import("@/views/Plants/Ingredients/Ingredient.vue"),
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "ingredients",
      breadcrumb: [
        {
          to: "/plants",
          text: "Plants",
        },
        {
          to: ":id",
          text: ":id",
        },
      ],
    },
  },
  {
    path: "/ration/nutrients",
    name: "nutrients",
    component: () => import("@/views/Plants/Nutrients/Nutrients.vue"),
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "nutrients",
      breadcrumb: [
        {
          to: "/plants",
          text: "Plants",
        },
        {
          to: ":id",
          text: ":id",
        },
      ],
    },
  },
  {
    path: "/ration/ingredients/prices",
    name: "ingredientsPrices",
    component: () => import("@/views/Plants/Ingredients/Prices.vue"),
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "ingredientPrices",
      breadcrumb: [
        {
          to: "/plants",
          text: "Plants",
        },
        {
          to: ":id",
          text: ":id",
        },
      ],
    },
  },
  {
    path: "/production-rations/",
    name: "productionRations",
    component: () =>
      import(
        "@/views/Plants/Rations/ProductionRationList/ProductionRationList.vue"
      ),
    exact: true,
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "productionRations",
      breadcrumb: [
        {
          to: "/",
          text: "",
        },
      ],
    },
  },
  {
    path: "/rations/:tur",
    name: "plantRations",
    component: () => import("@/views/Plants/Rations/RationList/RationList.vue"),
    exact: true,
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "rations",
      breadcrumb: [
        {
          to: "/",
          text: "",
        },
      ],
    },
  },
  {
    path: "/plant/:id/ration/:rationid/:tur",
    name: "rationView",
    component: () => import("@/views/Plants/Rations/RationView/RationView.vue"),
    exact: true,
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "plantRations",

      pageTitle: "rationInformation",
      breadcrumb: [
        {
          to: "/plants",
          text: "Plants",
        },
      ],
    },
  },
  {
    path: "/plant/:id/production-ration/:rationid/:tur",
    name: "productionRationView",
    component: () =>
      import(
        "@/views/Plants/Rations/ProductionRationList/ProductionRationView.vue"
      ),
    exact: false,
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "plantRations",

      pageTitle: "productionRationInformation",
      breadcrumb: [{}],
    },
  },
];
