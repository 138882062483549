import { getUserData } from "@/auth/utils";
import axios from "@axios";
import VueI18n from "@/libs/i18n";

export default {
  namespaced: true,
  state: {
    plantData: [],
    selectedPlant: {},
  },
  getters: {},
  mutations: {
    SET_PLANTS(state, payload) {
      state.plantData = payload;
    },
    SET_PLANT(state, payload) {
      state.selectedPlant = payload;
    },
  },
  actions: {
    async fetchPlants(ctx) {
      var response = await axios.get("/Plants/GetUsersPlants");
      if (response.data.status != "OK") return null;
      ctx.commit("SET_PLANTS", response.data.resultdata);

      return response.data.resultdata;
    },

    async fetchPlant(ctx, { id, dispatch }) {
      let plant = ctx.state.plantData.filter((x) => x.id == id);
      const companyid = getUserData().companyid;
      if (plant.length == 0) {
        await ctx.dispatch("fetchPlants");
        plant = ctx.state.plantData.filter((x) => x.id == id);
      }
      ctx.commit("SET_PLANT", plant[0]);
      return plant[0];
    },
    async fetchPlantMilkingTimes(ctx) {
      var user = getUserData();
      var response = await axios.get(`/Plants/GetPlantMilkingTimes/`);
      if (response.data.status != "OK") return null;
      user = {
        ...user,
        milkingTimes: response.data.resultdata,
        milkingCount: response.data.resultdata.length,
      };
      localStorage.setItem("userData", JSON.stringify(user));
      return response.data.resultdata;
    },
    async addPlantMilkingTimes(ctx, { milkingTimes }) {
      var response = await axios.post(
        `/Plants/AddPlantMilkingTimes/`,
        milkingTimes
      );
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },
    async updatePlantMilkingTimes(ctx, { milkingTimes }) {
      var response = await axios.put(
        `/Plants/UpdatePlantMilkingTimes/`,
        milkingTimes
      );
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },

    async fetchPlantById(ctx, { id }) {
      var response = await axios.get(`/Plants/GetPlantById/${id}`);
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },
    async deletePlant(ctx, plantid) {
      var response = {};
      return await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            response = await axios.delete(`/Plants/DeletePlant/${plantid}`, {
              headers: { culture: getUserData().cultureinfo },
            });
            return response.data.status == "OK";
          } else throw Error("iptal");
        });
    },
    async deletePlantMilkingTimes(ctx, { milkingTimes }) {
      var response = {};
      return await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            response = await axios.delete(`/Plants/DeletePlantMilkingTimes/`, {
              data: milkingTimes,
            });
            return response.data.status == "OK";
          }
        });
    },
    async deleteIngredient(ctx, { plantid, ingredientid }) {
      var response = {};
      return await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            response = await axios.delete(
              `/Ingredients/DeleteIngredient/${ingredientid}`,
              {
                headers: {
                  plantid: plantid,
                },
              }
            );
            return response.data.status == "OK";
          } else throw Error("iptal");
        });
    },
    async addPlant(ctx, { plant, sourceId }) {
      var response = await axios.post("/Plants/AddPlant/", plant, {
        headers: {
          sourceplantid: sourceId,
        },
      });
      return response.data.status == "OK";
    },
    async updatePlant(ctx, plant) {
      var response = await axios.put(`/Plants/UpdatePlant/${plant.id}`, plant);

      return response.data.status == "OK";
    },
    async getPlantUser(ctx, plantId) {
      var response = await axios.get("/Plants/GetPlantUsers", {
        headers: {
          plantid: plantId,
        },
      });
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },
    async updatePlantType(ctx, { plantid, planttype }) {
      var response = await axios.put(
        `/Plants/UpdatePlantType/${plantid}`,
        JSON.stringify(planttype),
        {
          headers: {
            planttype: planttype,
          },
        }
      );
      return response.data.status == "OK";
    },
    async updatePlantUser(ctx, { plantid, ids }) {
      var response = await axios.put(
        `/Plants/UpdatePlantUsers/${plantid}`,
        JSON.stringify(ids),
        {
          headers: {
            plantid: plantid,
          },
        }
      );
      return response.data.status == "OK";
    },
    async fetchPlantVisits(ctx, plantid) {
      var response = await axios.get(
        `/PlantVisits/GetVisitsByPlantid/${plantid}`
      );
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },
    async fetchPlantOrders(ctx, plantid) {
      var response = await axios.get(`/Orders/GetOrdersByPlantid/${plantid}`);
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },
    async fetchIngredients(ctx, plantid) {
      var response = await axios.get("/Ingredients/GetIngredients/", {
        headers: {
          plantid: plantid,
          culture: getUserData().cultureinfo,
        },
      });
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },
    async fetchNutrients(ctx, plantid) {
      var response = await axios.get("/Nutrients/GetNutrients/", {
        headers: {
          plantid: plantid,
          culture: getUserData().cultureinfo,
        },
      });
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },
    async addNutrient(ctx, newNutrient) {
      var response = await axios.post("/Nutrients/AddNutrient/", newNutrient, {
        headers: {
          culture: "en",
        },
      });
      return response.data.status == "OK";
    },
    async updateNutrient(ctx, { nutrient, values, plantid }) {
      var response = await axios.put(
        `/Nutrients/UpdateNutrient/${nutrient.id}`,
        nutrient,
        {
          headers: {
            culture: getUserData().cultureinfo,
            plantid: plantid,
          },
        }
      );
      if (response.data.status == "OK") {
        var result = await axios.put(
          `/Nutrients/UpdateDegerler/${nutrient.id}`,
          values,
          {
            headers: {
              culture: getUserData().cultureinfo,
              plantid: plantid,
            },
          }
        );
      }
      return result.data.status == "OK";
    },

    async deleteNutrient(ctx, { nutrientid, plantid }) {
      var response = {};
      return await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            response = await axios.delete(
              `/Nutrients/DeleteNutrient/${nutrientid}`,
              {
                headers: {
                  culture: getUserData().cultureinfo,
                  plantid: plantid,
                },
              }
            );
            return response.data.status == "OK";
          } else throw Error("iptal");
        });
    },
  },
};
