import axios from "@axios";
import VueI18n from "@/libs/i18n";

export default {
  async fetchSystemParameters(ctx, plantid) {
    var response = await axios.get("Definitions/GetSystemParameters", {
      headers: {
        plantid,
      },
    });
    if (response.data.status != "OK") return null;
    ctx.commit("SET_PARAMETERS", response.data.resultdata);

    return response.data.resultdata;
  },

  async addSystemParameter(ctx, systemparameter) {
    var response = await axios.post(
      "/Definitions/AddSystemParameters/",
      systemparameter
    );
    return response.data.status == "OK";
  },

  async updateSystemParameter(ctx, systemparameter) {
    var response = await axios.put(
      `/Definitions/UpdateSystemParameters/${systemparameter.id}`,
      [
        {
          id: systemparameter.id,
          deger: systemparameter.deger,
        },
      ]
    );
    return response.data.status == "OK";
  },

  async deleteSystemParameter(ctx, systemparameterid) {
    var response = {};
    return await this._vm
      .$swal({
        title: VueI18n.t("areYouSure"),
        text: VueI18n.t("notRevert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: VueI18n.t("yes"),
        cancelButtonText: VueI18n.t("no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
      .then(async (result) => {
        if (result.value) {
          var response = await axios.delete(
            `/Definitions/DeleteSystemParameters/${systemparameterid}`
          );
          return response.data.status == "OK";
        } else return false;
      });
  },
};
