import axios from "@axios";
import VueI18n from "@/libs/i18n";

export default {
  async fetchSynchronizations(ctx) {
    var response = await axios.get("/Definitions/GetSyncs");
    if (response.data.status != "OK") return null;
    ctx.commit("SET_SYNCHRONIZATIONS", response.data.resultdata);

    return response.data.resultdata;
  },
  async fetchSynchronizationStepsBySyncId(ctx, id) {
    var response = await axios.get(`/Definitions/GetSyncStepsBySyncid/${id}`);
    if (response.data.status != "OK") return null;
    ctx.commit("SET_SYNCHRONIZATION", response.data.resultdata);

    return response.data.resultdata;
  },
  async fetchSyncHormones(ctx) {
    var response = await axios.get("/Definitions/GetSyncHormones");
    if (response.data.status != "OK") return null;
    return response.data.resultdata;
  },
  async fetchSyncMethods(ctx) {
    var response = await axios.get("/Definitions/GetSyncMethods");
    if (response.data.status != "OK") return null;
    return response.data.resultdata;
  },
  async addSync(ctx, sync) {
    var response = await axios.post("/Definitions/AddSync", sync);
    return response.data.status != "OK";
  },
  async addSyncStep(ctx, step) {
    var response = await axios.post("/Definitions/AddSyncStep", step);
    return response.data.status != "OK";
  },
  async updateSyncStep(ctx, step) {
    var response = await axios.put(
      `/Definitions/UpdateSyncStep/${step.id}`,
      step
    );
    return response.data.status != "OK";
  },
  async updateSync(ctx, sync) {
    var response = await axios.put(`/Definitions/UpdateSync/${sync.id}`, sync);
    return response.data.status != "OK";
  },

  async deleteSyncStep(ctx, syncStepId) {
    var response = {};
    return await this._vm
      .$swal({
        title: VueI18n.t("areYouSure"),
        text: VueI18n.t("notRevert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: VueI18n.t("yes"),
        cancelButtonText: VueI18n.t("no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
      .then(async (result) => {
        if (result.value) {
          var response = await axios.delete(
            `/Definitions/DeleteSyncStep/${syncStepId}`
          );
          return response.data.status == "OK";
        } else throw new Error("cancelled");
      });
  },
  async deleteSync(ctx, syncId) {
    var response = {};
    return await this._vm
      .$swal({
        title: VueI18n.t("areYouSure"),
        text: VueI18n.t("notRevert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: VueI18n.t("yes"),
        cancelButtonText: VueI18n.t("no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
      .then(async (result) => {
        if (result.value) {
          var response = await axios.delete(
            `/Definitions/DeleteSync/${syncId}`
          );
          return response.data.status == "OK";
        } else throw new Error("cancelled");
      });
  },
};
